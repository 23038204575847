header.header {
  position:absolute;
  @media (max-width:1200px) {
    position:relative;
  }
  display:block;
  z-index:2000;
  left:0;
  top:0;
  width:100%;
  nav {
    position:absolute;
    top:30px;
    width:1170px;
    left:50%;
    margin-left:-585px;
    .nav-fixed & {
      position:fixed;
      top:0;
      background:white;
      left:0;
      width:100%;
      margin-left:0;
      max-height:0;
      transition:max-height 0.6s linear;
      opacity:0;
      box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
    }
    @media (max-width:1200px) {
      position:relative;
      top:0;
      background:white;
      left:0;
      width:100%;
      margin-left:0;
      transition:max-height 0.6s linear;
      box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
    }
    .scroll & {
      max-height:950px;
      opacity:1;
    }
  }
  .wrap {
    background:white;
  }
  a.logo {
    display:inline-block;
    width:250px;
    margin:15px;
    transition:opacity 0.15s linear;
    @media (max-width:550px) {
      width:185px;
      margin:27px 0 0;
    }
    .nav-fixed & {
      width:150px;
      margin:10px;
      display:inline-block;
    }
    img {
      width:100%;
    }
    &:hover {
      opacity:0.5;
    }
  }
  ul {
    padding:0;
    margin:0;
    list-style:none;
  }
  ul.nav {
    float:right;
    margin-right:15px;
    ul.sub-nav {
      position:absolute;
      top:110px;
      left:0;
      background:white;
      padding:0 15px;
      max-height:0;
      overflow:hidden;
      opacity:0;
      transition:max-height 0.7s linear,opacity 0.18s linear;
      .nav-fixed & {
        top:65px;
        box-shadow: 0 8px 8px 0 rgba(0,0,0,0.3);
      }
      li.sub-nav {
        border-bottom:1px dotted $grey*2.5;
        &:last-child {
          border:none;
        }
      }
      a.sub-nav {
        @include font(14px,50px,$color:$grey*2);
        text-decoration:none;
        white-space:nowrap;
        display:block;
        transition:color 0.15s linear;
        position:relative;
        &:after {
          position:absolute;
          content:'';
          width:0;
          left:0;
          height:1px;
          bottom:12px;
          background:$wine;
          transition:width 0.15s linear;
        }
        &:hover {
          color:$wine;
          &:after {
            width:100%;
          }
        }
      }
    }
    li.nav {
      position:relative;
      display:inline-block;
      &:hover {
        a.nav {
          background:$wine;
          color:white;
        }
        ul.sub-nav {
          opacity:1;
          max-height:900px;
          border-bottom:5px $wine solid;
        }
      }
      &:last-child {
        ul.sub-nav {
          right:0;
          left:auto;
        }
      }
    }
    a.nav {
      display:inline-block;
      padding:0 15px;
      @media (max-width:1200px) {
        padding:0 7px;
      }
      @media (max-width:1050px) {
        padding:0 3px;
      }
      @include font(16px,110px,$color:$grey);
      text-decoration:none;
      transition:background 0.15s linear,color 0.15s linear;
      text-transform:uppercase;
      &:hover {
        background:$wine;
        color:white;
      }
      .nav-fixed & {
        line-height:65px;
      }
    }
  }
  a.lang {
    margin-left:20px;
    @media (max-width:550px) {
      margin-left:10px;
    }
    float:right;
    line-height:110px;
    position:relative;
    transition:opacity 0.15s linear;
    &:hover {
      opacity:0.5;
    }
    &:before {
      content:'';
      position:absolute;
      left:-20px;
      @media (max-width:550px) {
        left:-15px;
      }
      top:50%;
      width:2px;
      background:$grey;
      height:16px;
      margin-top:-8px;
    }
    img {
      margin-top:-2px;
      height:14px;
      vertical-align:middle;
      display:inline-block;
    }
    .nav-fixed & {
      line-height:65px;
    }
  }
  @media (max-width:1000px) {
    ul.nav {
      max-height:0;
      overflow:hidden;
      transition:max-height 0.3s linear;
      opacity:0;
      float:none;
      margin:0;
      width:100%;
      .menu-responsive & {
        max-height:1200px;
        opacity:1;
      }
      a.nav {
        padding:0;
        text-align:center;
        width:100%;
        line-height:80px;
      }
      li {
        width:100%;
        margin:0;
      }
      ul.sub-nav {
        position:relative;
        top:0;
        transition:max-height 0.15s linear;
        padding-bottom:5px;
        background:$grey*4.8;
        border-bottom:3px $wine solid;
      }
    }
  }
}
.breakpoint-nav {
  position:absolute;
  top:200px;
  left:0;
  width:100%;
  .menu-responsive & {
    display:none;
  }
}
.button-responsive-menu {
  display:none;
  @media (max-width:1000px) {
    transition:background 0.15s linear;
    float:right;
    position:relative;
    display:block;
    margin:25px 15px 0 0;
    height:40px;
    .nav-fixed & {
      margin-top:3px;
    }
    @include font(24px,40px,$weight:400,$color:$wine);
    vertical-align:middle;
    &:hover {
      background:$grey*4;
    }
    text-transform:uppercase;
    padding:10px 43px 10px 10px;
    overflow:hidden;
    cursor:pointer;
    @media (max-width:550px) {
      padding-right:30px;
      margin-right:10px;
      .text {
        display:none;
      }
    }
    span {
      width:20px;
      @media (max-width:550px) {
        width:25px;
      }
      height:3px;
      background:$wine;
      display:block;
      position:absolute;
      right:8px;
      margin-top:-1px;
      transition:top 0.15s linear,transform 0.15s linear,opacity 0.15s linear;
      &.line-1 {
        top:35%;
        .menu-responsive & {
          transform:rotate3d(0,0,1,45deg);
          top:50%;
        }
      }
      &.line-2 {
        top:50%;
        .menu-responsive & {
          opacity:0;
        }
      }
      &.line-3 {
        top:65%;
        .menu-responsive & {
          transform:rotate3d(0,0,1,-45deg);
          top:50%;
        }
      }
    }
  }
}