ul.tour-program {
  list-style:none;
  padding:0;
  li {
    border-top:1px solid $grey*3.5;
    padding:8px 3px 8px 150px;
    @media (max-width:768px) {
      padding-left:110px;
    }
    position:relative;
    &:last-child {
      border-bottom:1px solid $grey*3.5;
    }
    strong {
      position:absolute;
      top:8px;
      left:6px;
    }
    &:after {
      position:absolute;
      content:'';
      background:$grey*4.75;
      left:0;
      top:0;
      width:0;
      height:100%;
      z-index:-1;
      transition:width 0.15s linear;
    }
    &:hover:after {
      width:100%;
    }
  }
  &.tour-services {
    li {
      strong {
        left:0;
        top:50%;
        width:150px;
        text-align:center;
        margin-top:-15px;
        @media (max-width:768px) {
          width:110px;
        }
      }
      i {
        font-size:30px;
      }
    }
  }
  &.no-icons li {
    padding-left:3px;
  }
}
table.prix, table.prix-column-3 {
  width:100%;
  th, td {
    border:1px solid $grey*4;
    box-sizing:border-box;
    text-align:center;
    padding:8px;
    @include font(18px,1.35em,$grey*2.5,400);
  }
  th {
    font-weight:500;
    color:white;
    background:$wine;
  }
  td:first-child {
    background:$grey*4.7;
  }
}
table.prix {
  @media (max-width:600px) {
    tr {
      float:left;
      width:50%;
      td,th {
        width:100%;
        display:block;
        &:first-child {
          height:60px;
        }
      }
    }
  }
}
.wrap-page {
  img.logo {
    float:left;
    max-width:25%;
    margin:30px 30px 5px 0;
  }
  ul.img-page {
    list-style:none;
    padding:15px 0 0;
    li {
      float:left;
      width:45%;
      margin-right:5%;
      &:last-child {
        margin-left:5%;
        margin-right:0;
      }
      @media (max-width:580px) {
        width:100%;
        &:last-child {
          margin-left:0;
        }
        &:first-child {
          margin-right:0;
          margin-bottom:30px;
        }
      }
      img {
        width:100%;
      }
    }
  }
}