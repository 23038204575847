.icon-zoom:before {
  content: "\e916";
}
.icon-calendar:before {
  content: "\e915";
}
.icon-service:before {
  content: "\e913";
}
.icon-vespa:before {
  content: "\e914";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-bell:before {
  content: "\e901";
}
.icon-clock:before {
  content: "\e902";
}
.icon-comment:before {
  content: "\e903";
}
.icon-distance:before {
  content: "\e904";
}
.icon-drink:before {
  content: "\e905";
}
.icon-duration:before {
  content: "\e906";
}
.icon-facebook:before {
  content: "\e907";
}
.icon-food:before {
  content: "\e908";
}
.icon-left:before {
  content: "\e909";
}
.icon-level:before {
  content: "\e90a";
}
.icon-like:before {
  content: "\e90b";
}
.icon-mastercard:before {
  content: "\e90c";
}
.icon-paypal:before {
  content: "\e90d";
}
.icon-right:before {
  content: "\e90e";
}
.icon-share:before {
  content: "\e90f";
}
.icon-tripadvisor:before {
  content: "\e910";
}
.icon-van:before {
  content: "\e911";
}
.icon-visa:before {
  content: "\e912";
}