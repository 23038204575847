$blue: #0089ec !default;
$blue-hover: #b1dcfb !default;
$black: #000 !default;
$white: #fff !default;
$bg-white: $white !default;
$bg-grey-light: #f2f2f2 !default;
$border-grey: #777 !default;
$border-grey-light: #ddd !default;
$border-select: darken( $border-grey-light, 15% ) !default;
$clear-red: #e20 !default;
$picker-z-index: 10000 !default;
$speed-animate-in: .15s !default;
$input-active-border: $blue !default;
$base-font-size: 16px !default;
$base-line-height: 1.2 !default;
$picker-border-radius: 5px !default;
$picker-box-shadow: 0 12px 36px 16px rgba(0,0,0,.24) !default;
$picker-box-shadow-light: 0 6px 18px 1px rgba(0,0,0,.12) !default;
$breakpoint-tiny: 26.5em !default;
$breakpoint-small: 33.875em !default;
$breakpoint-medium: 40.125em !default;
$breakpoint-large: 46.75em !default;
$breakpoint-width-tiny: 24.5em !default;
$year-weekday-label: #999 !default;
$blue-tag: #0059bc !default;
$disabled-tag: #aaa !default;
$disabled-things-bg: #f5f5f5 !default;
$disabled-things-text: #ddd !default;
$disabled-highlighted-things-bg: #bbb !default;
$picker-min-width: 256px !default;
$picker-max-width: 666px !default;
$time-min-width: $picker-min-width !default;
$time-max-width: 320px !default;
$classic-max-width: $picker-max-width - 200px !default;
$classic-min-width: $picker-min-width - 80px !default;
$classic-max-height: 25em !default;
$classic-box-shadow: 0 6px 18px 1px rgba(0,0,0,.12) !default;