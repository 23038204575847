%picker-item-highlighted{
    border-color: $blue;
}
%picker-item-hovered{
    cursor: pointer;
    color: $black;
    background: $blue-hover;
}
%picker-item-selected{
    background: $blue;
    color: $white;
}
%picker-item-disabled{
    background: $disabled-things-bg;
    border-color: $disabled-things-bg;
    color: $disabled-things-text;
    cursor: default;
}
@mixin opacity( $decimal ) {
    $percent: $decimal * 100;
    -moz-opacity: $decimal;
         opacity: $decimal;
}
@mixin box-shadow ( $arguments... ) {
    -webkit-box-shadow: $arguments;
       -moz-box-shadow: $arguments;
            box-shadow: $arguments;
}
@mixin box-sizing ( $arguments... ) {
    -webkit-box-sizing: $arguments;
       -moz-box-sizing: $arguments;
            box-sizing: $arguments;
}
@mixin border-radius ( $arguments... ) {
    -webkit-border-radius: $arguments;
       -moz-border-radius: $arguments;
            border-radius: $arguments;
}
@mixin transition ( $arguments... ) {
    -webkit-transition: $arguments;
       -moz-transition: $arguments;
            transition: $arguments;
}
@mixin transform ( $arguments... ) {
    -webkit-transform: $arguments;
       -moz-transform: $arguments;
            transform: $arguments;
}
@mixin user-select ( $arguments... ) {
    -webkit-user-select: $arguments;
       -moz-user-select: $arguments;
        -ms-user-select: $arguments;
            user-select: $arguments;
}