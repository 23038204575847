@import "variables";
@import "mixins";

.picker {}
.picker__holder,
.picker__frame {
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
}
.picker__holder {
  position: fixed;
  $transition: background #{$speed-animate-in} ease-out, top 0s #{$speed-animate-in};
  @include transition( $transition );
  -webkit-backface-visibility: hidden;
}
.picker__frame {
  position: absolute;
  margin: 0 auto;
  min-width: $picker-min-width;
  max-width: $picker-max-width;
  width: 100%;
  @include opacity( 0 );
  @include transition( all $speed-animate-in ease-out );
  @media ( min-height: $breakpoint-small ) {
    overflow: visible;
    top: auto;
    bottom: -100%;
    max-height: 80%;
  }
  @media ( min-height: $breakpoint-medium ) {
    margin-bottom: 7.5%;
  }
}
.picker__wrap {
  display: table;
  width: 100%;
  height: 100%;
  @media ( min-height: $breakpoint-small ) {
    display: block;
  }
}
.picker__box {
  background: $bg-white;
  display: table-cell;
  vertical-align: middle;
  @media ( min-height: $breakpoint-tiny ) {
    font-size: 1.25em;
  }
  @media ( min-height: $breakpoint-small ) {
    display: block;
    font-size: 1.33em;
    border: 1px solid $border-grey;
    border-top-color: lighten( $border-grey, 7% );
    border-bottom-width: 0;
    @include border-radius( $picker-border-radius $picker-border-radius 0 0 );
    @include box-shadow( $picker-box-shadow );
  }
  @media ( min-height: $breakpoint-medium ) {
    font-size: 1.5em;
    border-bottom-width: 1px;
    @include border-radius( $picker-border-radius );
  }
}
.picker--opened {
  .picker__holder {
    top: 0;
    background: transparent;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#1E000000,endColorstr=#1E000000)"; // IE8
    zoom: 1;
    background: rgba(0,0,0,.32);
    $transition: background $speed-animate-in ease-out;
    @include transition( $transition );
  }
  .picker__frame {
    top: 0;
    @include opacity( 1 );
    @media ( min-height: $breakpoint-small ) {
      top: auto;
      bottom: 0;
    }
  }
}




.picker__box {
  padding: 0 1em;
}
.picker__header {
  text-align: center;
  position: relative;
  margin-top: .75em;
}
.picker__month,
.picker__year {
  font-weight: 500;
  display: inline-block;
  margin-left: .25em;
  margin-right: .25em;
}
.picker__year {
  color: $year-weekday-label;
  font-size: .8em;
  font-style: italic;
}
.picker__select--month,
.picker__select--year {
  border: 1px solid $border-select;
  height: 2em;
  padding: .5em;
  margin-left: .25em;
  margin-right: .25em;
  @media ( min-width: $breakpoint-width-tiny ) {
    margin-top: -.5em;
  }
}
.picker__select--month {
  width: 35%;
}
.picker__select--year {
  width: 22.5%;
}
.picker__select--month:focus,
.picker__select--year:focus {
  border-color: $blue;
}
.picker__nav--prev,
.picker__nav--next {
  position: absolute;
  padding: .5em 1.25em;
  width: 1em;
  height: 1em;
  box-sizing: content-box;
  top: -.25em;
  @media ( min-width: $breakpoint-width-tiny ) {
    top: -.33em;
  }
}
.picker__nav--prev {
  left: -1em;
  padding-right: 1.25em;
  @media ( min-width: $breakpoint-width-tiny ) {
    padding-right: 1.5em;
  }
}
.picker__nav--next {
  right: -1em;
  padding-left: 1.25em;
  @media ( min-width: $breakpoint-width-tiny ) {
    padding-left: 1.5em;
  }
}
.picker__nav--prev:before,
.picker__nav--next:before {
  content: " ";
  border-top: .5em solid transparent;
  border-bottom: .5em solid transparent;
  border-right: .75em solid $black;
  width: 0;
  height: 0;
  display: block;
  margin: 0 auto;
}
.picker__nav--next:before {
  border-right: 0;
  border-left: .75em solid $black;
}
.picker__nav--prev:hover,
.picker__nav--next:hover {
  @extend %picker-item-hovered;
}
.picker__nav--disabled,
.picker__nav--disabled:hover,
.picker__nav--disabled:before,
.picker__nav--disabled:before:hover {
  cursor: default;
  background: none;
  border-right-color: $disabled-things-bg;
  border-left-color: $disabled-things-bg;
}
.picker__table {
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  font-size: inherit;
  width: 100%;
  margin-top: .75em;
  margin-bottom: .5em;
  @media ( min-height: $breakpoint-small ) {
    margin-bottom: .75em;
  }
}
.picker__table td {
  margin: 0;
  padding: 0;
}
.picker__weekday {
  width: 14.285714286%;
  font-size: .75em;
  padding-bottom: .25em;
  color: $year-weekday-label;
  font-weight: 500;
  @media ( min-height: $breakpoint-small ) {
    padding-bottom: .5em;
  }
}
.picker__day {
  padding: .3125em 0;
  font-weight: 200;
  border: 1px solid transparent;
}
.picker__day--today {
  position: relative;
}
.picker__day--today:before {
  content: " ";
  position: absolute;
  top: 2px;
  right: 2px;
  width: 0;
  height: 0;
  border-top: .5em solid $blue-tag;
  border-left: .5em solid transparent;
}
.picker__day--disabled:before {
  border-top-color: $disabled-tag;
}
.picker__day--outfocus {
  color: $disabled-things-text;
}
.picker__day--infocus:hover,
.picker__day--outfocus:hover {
  @extend %picker-item-hovered;
}
.picker__day--highlighted {
  @extend %picker-item-highlighted;
}
.picker__day--highlighted:hover,
.picker--focused .picker__day--highlighted {
  @extend %picker-item-hovered;
}
.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
  @extend %picker-item-selected;
}
.picker__day--disabled,
.picker__day--disabled:hover,
.picker--focused .picker__day--disabled {
  @extend %picker-item-disabled;
}
.picker__day--highlighted.picker__day--disabled,
.picker__day--highlighted.picker__day--disabled:hover {
  background: $disabled-highlighted-things-bg;
}
.picker__footer {
  text-align: center;
}
.picker__button--today,
.picker__button--clear,
.picker__button--close {
  border: 1px solid $white;
  background: $white;
  font-size: .8em;
  padding: .66em 0;
  font-weight: bold;
  width: 33%;
  display: inline-block;
  vertical-align: bottom;
}
.picker__button--today:hover,
.picker__button--clear:hover,
.picker__button--close:hover {
  @extend %picker-item-hovered;
  border-bottom-color: $blue-hover;
}
.picker__button--today:focus,
.picker__button--clear:focus,
.picker__button--close:focus {
  background: $blue-hover;
  border-color: $blue;
  outline: none;
}
.picker__button--today:before,
.picker__button--clear:before,
.picker__button--close:before {
  position: relative;
  display: inline-block;
  height: 0;
}
.picker__button--today:before,
.picker__button--clear:before {
  content: " ";
  margin-right: .45em;
}
.picker__button--today:before {
  top: -.05em;
  width: 0;
  border-top: .66em solid $blue-tag;
  border-left: .66em solid transparent;
}
.picker__button--clear:before {
  top: -.25em;
  width: .66em;
  border-top: 3px solid $clear-red;
}
.picker__button--close:before {
  content: "\D7";
  top: -.1em;
  vertical-align: top;
  font-size: 1.1em;
  margin-right: .35em;
  color: $border-grey;
}
.picker__button--today[disabled],
.picker__button--today[disabled]:hover {
  @extend %picker-item-disabled;
}
.picker__button--today[disabled]:before {
  border-top-color: $disabled-tag;
}






