@keyframes errors {
    20%   {background-color: $red;}
    50%  {background-color: $red/1.25;}
    80% {background-color: $red;}
}
#send-ok {
    padding:15px 35px;
    background:$grey*4.5;
    text-align:center;
    p {
        font-size:21px;
        @media (max-width:768px) {
            font-size:17px;
        }
    }
    strong {
        font-size:30px;
        @media (max-width:768px) {
            font-size:25px;
        }
    }
}
.form-page {
    .errors-form {
        color:white;
        padding:10px 50px;
        background-color:red;
        animation-name:errors;
        animation-duration:3s;
        animation-iteration-count: infinite;
    }
    form {
        ul {
            list-style:none;
            padding:0;
            margin:0;
            &.last {
                margin-bottom:35px;
            }
        }
        label {
            display:block;
            padding-left:15px;
            margin-bottom:5px;
            @include font(17px,1.35em,black,500);
        }
        .content {
            margin:0 15px;
            @media (max-width:950px) {
                margin:0 5px;
            }
        }
        li.col-3 {
            li {
                float:left;
                width:$w-100/3;
                margin-top:35px;
                padding-right:20px;
                box-sizing:border-box;
                &:first-child {
                    .content {
                        margin:0 30px 0 0;
                        @media (max-width:950px) {
                            margin:0 10px 0 0;
                        }
                    }
                }
                &:last-child {
                    .content {
                        margin:0 0 0 30px;
                        @media (max-width:950px) {
                            margin:0 0 0 10px;
                        }
                    }
                }
                @media (max-width:768px) {
                    width:50%;
                }
                @media (max-width:580px) {
                    width:100%;
                    margin-top:15px;
                }
                &,&:last-child,&:first-child {
                    .content {
                        margin:0 7px;
                    }
                }
            }
            input, .radiobox {
                display:block;
                width:96%;
                @include font(17px,1.35em,$grey*3,400);
                margin:0;
            }
            input {
                border:1px solid $grey*4;
                background:$grey*4.9;
                box-sizing:border-box;
                padding:8px 2%;
            }
            .radiobox {
                line-height:36px;
                padding-left:15px;
                input {
                    display:inline-block;
                    width:auto;
                    margin:0 20px 0 5px;
                }
                span,input {
                    vertical-align:middle;
                }
            }
            select {
                margin:10px 0 0 15px;
            }
            &.wine-club-pack {
                ul {
                    width:100%;
                    display:flex;
                    justify-content:space-between;
                    flex-wrap:wrap;
                }
                li {
                    padding-right:30px;
                    @media (max-width:768px) {
                        width:100%;
                    }
                    @media (max-width:580px) {
                        margin-top:15px;
                    }
                    .content {
                        display:flex;
                        align-items:center;
                    }
                    input {
                        width:55px;
                    }
                }
            }
        }
        li.message {
            float:left;
            margin-top:35px;
            width:$w-100*(2/3);
            @media (max-width:950px) {
                width:100%;
            }
            @media (max-width:580px) {
                margin-top:15px;
            }
            .content {
                margin:0;
            }
            textarea {
                display:block;
                box-sizing:border-box;
                border:1px solid $grey*4;
                background:$grey*4.9;
                width:96%;
                padding:8px 2%;
                @include font(17px,1.35em,$grey*3,400);
                min-height:125px;
            }
        }
        li.last {
            label {
                padding-left:0;
                input {
                    margin-right:8px;
                }
            }
            float:left;
            width:$w-100*(1/3);
            margin-top:60px;
            @media (max-width:950px) {
                width:100%;
                margin-top:25px;
            }
            @media (max-width:580px) {
                margin-top:15px;
            }
            details {
                strong {
                    @include font(14px,1.05em,black,500);
                }
                p {
                    @include font(13px,1.05em,$grey*3,400);
                }
            }
            label.spunta {
                margin-top:20px;
            }
        }
        @media (max-width:768px) {
            li.message, li.last {
                .content {
                    margin:0 7px;
                }
            }
        }
    }
    #button-form {
        display:block;
        text-align:center;
        background:$wine;
        transition:background 0.15s linear;
        @include font(25px,1em,white);
        padding:10px 25px;
        margin: 35px auto 45px;
        text-transform:uppercase;
        border:1px solid $wine/2;
        &:hover {
            background:$grey;
        }
    }
}