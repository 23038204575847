.top-tours,.tour-list {
  position:relative;
  margin-top:80px;
  @media (max-width:768px) {
    margin-top:-10px;
  }
  &.wrap-2 {
    padding:0 70px;
    @media (max-width:1150px) {
      padding:0 45px;
    }
  }
  ul {
    list-style:none;
    padding:0;
    margin:0;
    li.content {
      width:100%;
      li {
        float:left;
        width:$w-100/3;
        a {
          span.description {
            font-size:15px;
            line-height:17px;
            color:$grey*2;
            padding:15px 20px 0;
            display:block;
          }
          margin:0 5px;
          display:block;
          overflow:hidden;
          padding:8px 0 25px;
          box-shadow:0 0 3px rgba(black,0.3) inset;
          text-decoration:none;
          min-height:470px;
          box-sizing:border-box;
          position:relative;
          transition:background 0.25s linear,box-shadow  0.25s linear;
          &:hover {
            box-shadow:0 0 6px rgba(black,0.4) inset;
            background:$grey*4.9;
          }
          .img {
            display:block;
            width:100%;
            height:190px;
            background-size:cover;
            //image-rendering:pixelated;
            background-position:center center;
            background-repeat:no-repeat;
            background-image:url(/static/img/tours/wine_tour_cortona/miniature-box-link.jpg);
            transition:width 0.15s linear,margin-left 0.15s linear;
          }
          border-bottom:7px $wine solid;
          &:hover {
            .img {
              width:115%;
              margin-left:-7.5%;
            }
          }
        }
        h2,h3 {
          text-transform:uppercase;
          text-align:center;
          padding:0 10px;
          margin:0;
          word-wrap: break-word;
          color:$wine;
        }
        h2 {
          margin-top:25px;
          font-size:30px;
          line-height:0.85em;
        }
        h3 {
          font-size:15px;
          line-height:1.4em;
          color:$grey*2.5;
        }
        &.position-1 {
          a {
            margin:0 10px 0 0;
          }
        }
        &.position-3 {
          a {
            margin:0 0 0 10px;
          }
        }
        span.price {
          font-size:15px;
          color:$grey*3;
          display:block;
          width:100%;
          bottom:70px;
          left:0;
          text-align:center;
          position:absolute;
          span {
            color:$gold;
            font-size:28px;
            margin:0 7px;
            font-weight:700;
          }
        }
        span.more-info {
          display:block;
          width:150px;
          padding:7px 0;
          text-align:center;
          position:absolute;
          left:50%;
          margin-left:-75px;
          bottom:15px;
          background:$wine;
          border-radius:3px;
          transition:background 0.15s linear;
          color:white;
          font-size:13px;
          &:hover {
            background:$gold;
          }
        }
        &.fun-tour,&.bike-tour {
          span.more-info {
            background:$gold;
            &:hover {
              background:$wine;
            }
          }
          h2 {
            color:$gold;
          }
          span.price span {
            color:$wine;
          }
        }
        @media (max-width:968px) {
          width:100%;
          a,&.position-1 a,&.position-3 a {
            margin:0;
          }
          a {
            span.description {
              margin-left:30%;
              text-align:center;
            }
            padding:0;
            box-sizing:content-box;
            min-height:220px;
            .img {
              float:left;
              width:30%;
              height:220px;
            }
            &:hover {
              .img {
                width:30%;
                margin-left:0;
              }
            }
            h2 {
              padding-top:15px;
            }
          }
          span.more-info {
            left:100%;
            margin-left:-165px;
          }
          span.price {
            bottom:17px;
          }
        }
        @media (max-width:768px) {
          a {
            span.description {
              display:none;
            }
            min-height:125px;
            .img {
              display:none;
            }
          }
          h2 {
            font-size:22px;
          }
          span.more-info {
            display:none;
          }
          span.price {
            display:block;
            position:relative;
            left:auto;
            bottom:auto;
            margin:7px auto;
            margin-top:10px;
            font-size:13px;
            span {
              font-size:15px;
            }
          }
        }
      }
    }
  }
  .cycle-box-link-prev-next {
    position:absolute;
    top:50%;
    font-size:40px;
    color:$grey*4;
    cursor:pointer;
    margin-top:-28px;
    transition:color 0.15s linear;
    &:hover {
      color:$grey*2.9;
    }
  }
  .cycle-next-box-link {
    right:0;
    cursor:e-resize;
  }
  .cycle-prev-box-link {
    left:0;
    cursor:w-resize;
  }
  .pager-box-link {
    display:block;
    text-align:center;
    padding-top:15px;
    span {
      margin:0 10px;
      font-size:0;
      cursor:pointer;
      color:white;
      border:1px solid $grey*4;
      background:white;
      display:inline-block;
      width:10px;
      height:10px;
      border-radius:100%;
      box-shadow:0 0 3px rgba(black,0.3);
      transition:background 0.15s linear;
      &.cycle-pager-active {
        background:$wine;
      }
      &:hover {
        background:$grey*3.5;
      }
    }
  }
}
.tour-list {
  margin-bottom:100px;
  margin-top:0;
  ul li.content li {
    a {
      margin:0 20px 40px;
    }
    &.position-1 a {
      margin:0 40px 40px 0;
    }
    &.position-3 a {
      margin:0 0 40px 40px;
    }
    @media (max-width:968px) {
      & a, &:last-child a, &:first-child a {
        margin:0 0 30px;
      }
    }
  }
}