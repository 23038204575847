$facebook:#3b5998;
$wine:#741a3c;
$grey:#333333;
$gold:#cea757;
$green:#908a35;
$w-100:100%;
$red:red;

@mixin font($size:15px,$line-height:1.3em,$color:$grey,$weight:400,$fam:'Roboto') {
  color:$color;
  font-size:$size;
  line-height:$line-height;
  font-weight:$weight;
  font-family:$fam;
  @media (max-width:768px) {
    font-size:$size/1.25;
  }
}




body {
  font-family: 'Roboto', sans-serif;
  font-weight:400;
  @media(max-width:1200px) {
    &.nav-fixed {
      padding-top:110px;
    }
  }
}

.wrap, .wrap-2 {
  padding:0 15px;
  max-width:1170px;
  margin:0 auto;
  &.wrap-page {
    padding-top:100px;
    padding-bottom:50px;
  }
  p, ul , ol {
    @include font(18px,1.35em,$grey*2.5,400);
    strong {
      font-weight:500;
      color:$wine;
    }
    a {
      position:relative;
      color:$wine;
      transition:color 0.15s linear;
      &:hover {
        color:$grey*3.5;
      }
    }
  }
  @import url('https://fonts.googleapis.com/css?family=Abril+Fatface');

  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
  }
  ol li {
    counter-increment: my-awesome-counter;
    position:relative;
    padding:15px 0 15px 10px;
  }
  ol li::before {
    position:absolute;
    left:-40px;
    top:50%;
    transform:translateY(-50%);
    content: "0" counter(my-awesome-counter);
    background-color:$wine;
    display:inline-block;
    border-radius:100%;
    padding:5px 10px;
    color:white;
    font-size:13px;
  }

  h2 {
    @include font(29px,1.05em,$color:black,$weight:700);
    margin-top:45px;
  }
  h3 {
    @include font(23px,1.05em,$color:black,$weight:700);
  }
}
.wrap-2 {
  max-width:1000px;
}

.box-grey {
  background:$grey*4.75;
  &.box-link-header-home {
    position:relative;
    padding-top:450px;
    padding-bottom:35px;
    @media (max-width:1200px) {
      padding-top:50px;
    }
  }
}

.center {
  text-align:center;
}

.wrap h1, .wrap-2 h1 {
  @include font(30px,1.05em,$color:$wine,$weight:700);
  text-transform:uppercase;
  &.big {
    font-size:90px;
    @media (max-width:768px) {
      font-size:40px;
    }
    text-align:center;
    &.green {
      color:$green;
    }
  }
}

.wrap-page {
  h1.first,p.first {
    margin-top:0;
  }
  .left {
    float:left;
    max-width:40%;
    margin:0 75px 50px 0;
  }
  .right {
    float:right;
    max-width:40%;
    margin:0 0 50px 75px;
  }
  @media (max-width:768px) {
    .left {
      margin-right:35px;
    }
    .right {
      margin-left:35px;
    }
    .left,.right {
      margin-bottom:25px;
    }
  }
    @media (max-width:480px) {
    .left,.right {
      float:none;
      margin:0 auto 25px;
      max-width:100%;
    }
  }
  .wide {
    width:100%;
  }
}

.padding-top {
  margin-top:50px;
}
.padding-top-xl {
  margin-top:90px;
}
.padding-bottom {
  margin-bottom:50px;
}
.padding-bottom-xl {
  margin-bottom:90px;
}