.cookie-banner-wrapper,
.cookie-banner-wrapper * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.cookie-banner-wrapper {
  display: block;
  position: fixed;
  z-index: 10000;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  background: #000;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.4em;
}

.cookie-banner-wrapper p {
  color: #eee;
}

.cookie-banner-wrapper a,
.cookie-banner-wrapper .cookie-banner-link {
  color: #5bc0de;
  text-decoration: underline;
}

.cookie-banner-wrapper a:hover,
.cookie-banner-wrapper .cookie-banner-link:hover {
  color: #6cd1ef;
  text-decoration: underline;
}

.cookie-banner-btn-style {
  display: inline-block;
  padding: 6px 12px;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  color: #333;
  background-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  border-color: #ccc;
  background-repeat: repeat-x;
  background-image: linear-gradient(to bottom,#fff 0,#e0e0e0 100%);
  text-shadow: 0 1px 0 #fff;
}
.cookie-banner-btn-style:hover,
.cookie-banner-btn-style:focus {
  background-color: #e0e0e0;
  background-position: 0 -15px;
}
.cookie-banner-btn-style:active {
  background-color: #e0e0e0;
  border-color: #dbdbdb;
}

.cookie-banner-container {
  margin: 0 auto;
  width: 100%;
  padding: 20px;
}

.cookie-banner-button-container {
  text-align: right;
}

@media (min-width: 768px) {
  .cookie-banner-container {
    width: 750px;
    padding-left: 140px;
  }

  .cookie-banner-text {
    float: left;
    width: 100%;
    margin-left: -120px;
  }

  .cookie-banner-button-container {
    float: left;
    width: 120px;
    text-align: right;
    line-height: 38px;
  }

  .cookie-banner-container:after {
    content: "";
    display: block;
    clear: both;
  }
}


@media (min-width: 992px) {
  .cookie-banner-container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .cookie-banner-container {
    width: 1170px;
  }
}

/*
 * animation
 */
.cookie-banner-wrapper {
  display: block;
  bottom: -100%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.cookie-banner-hidden {
  bottom: -100%;
  transition: bottom .25s ease-in, opacity .25s, visibility .25s;
  opacity: 0;
  visibility: hidden;
}

.cookie-banner-visible {
  bottom: 0;
  opacity: 1;
  visibility: visible;
  transition: bottom .25s, opacity .5s, visibility .5s;
}








.cookie-modal * {
  box-sizing: border-box;
}

.cookie-modal .cookie-modal-backdrop {
  display: block;
  position: fixed;
  top: 0; left: 0; bottom: 0; right: 0;
  z-index: 10001;
  background: rgba(0,0,0,0.8);
}

.cookie-modal .cookie-modal-frame {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 90%;
  padding-top: 48px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10002;
  pointer-events: none
}

.cookie-modal .cookie-modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  background: #fff;
  pointer-events: auto;
  overflow: auto;
  -webkit-overflow-scrolling:touch
}

.cookie-modal .cookie-modal-content iframe {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  left:0;
  top:0;
}

.cookie-modal .cookie-modal-close-button {
  position: absolute;
  z-index: 10;
  margin: 0; padding: 0;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 24px;
  border: none;
  text-shadow: 0 0 2px rgba(0,0,0,0.2);
  text-decoration: none;
  text-align: center;
  color: #ccc !important;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  pointer-events: auto;
  cursor: pointer;
}

.cookie-modal .cookie-modal-close-button:hover {
  color: #fff !important;
}

/*
 * animation
 */
/* backdrop */
.cookie-modal .cookie-modal-backdrop {
  opacity: 0;
  visibility: hidden;
}

.cookie-modal-visible .cookie-modal-backdrop,
.cookie-modal-hidden .cookie-modal-backdrop {
  transition: opacity .5s, visibility .5s;
}

.cookie-modal-visible .cookie-modal-backdrop {
  opacity: 1;
  visibility: visible;
}

/* frame */
.cookie-modal .cookie-modal-frame {
  opacity: 0;
}

.cookie-modal-visible .cookie-modal-frame,
.cookie-modal-hidden .cookie-modal-frame {
  transition: opacity .5s;
}

.cookie-modal-visible .cookie-modal-frame {
  opacity: 1;
}