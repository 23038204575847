.box-tours-and-more-home {
  margin-top:70px;
  display:block;
  width:100%;
  background-size:cover;
  image-rendering:pixelated;
  background-position:center center;
  background-repeat:no-repeat;
  background-image:url(/static/img/home/bck-tour-and-more.jpg);
  .text-white {
    float:left;
    width:45%;
    padding-top:50px;
    hr {
      border-top:3px solid white;
      box-shadow:0 0 10px rgba(0,0,0,0.85);
      margin:45px 0;
    }
    h1,h2 {
      color:white;
      font-size:95px;
      text-shadow: 0 0 10px rgba(0,0,0,0.85);
      margin-bottom:0;
      margin-top:15px;
    }
    h1 {
      font-weight:200;
      font-size:45px;
      line-height:1em;
      margin:0;
    }
    p {
      color:white;
      text-shadow: 0 0 5px rgba(0,0,0,0.5);
    }
    @media (max-width:1920px) {
      h1 {
        font-size:2.5vw;
      }
      h2 {
        font-size:5vw;
      }
    }
    @media (max-width:768px) {
      width:100%;
      h1 {
        font-size:20px;
      }
      h2 {
        font-size:27px;
      }
      h1,h2 {
        text-align:center;
      }
    }
  }
  ul.link {
    list-style:none;
    margin:0;
    padding:50px 0 0;
    float:right;
    width:45%;
    @media (max-width:768px) {
      width:100%;
    }
    li {
      a {
        padding:15px;
        display:block;
        background:white;
        box-shadow:0 0 10px rgba(0,0,0,0.85);
        border-right:7px solid $wine;
        text-decoration:none;
        transition:opacity 0.15s linear;
        margin:0 0 50px;
        text-align:center;
        &:hover {
          opacity:0.9;
        }
        .img {
          float:left;
          width:165px;
          height:165px;
          margin-right:25px;
          background-size:cover;
          image-rendering:pixelated;
          background-position:center center;
          background-repeat:no-repeat;
          background-image:url(/static/img/home/bck-tour-and-more.jpg);
          @media (max-width:1200px) {
            display:none;
          }
        }
      }
      h1 {
        @include font(30px,0.85em,$color:$wine,$weight:700);
        margin:0 0 15px;
        @media (max-width:768px) {
          font-size:23px;
        }
      }
      span.more-info {
        display:inline-block;
        padding:4px 10px;
        border-radius:3px;
        color:white;
        background:$wine;
        transition:background 0.15s linear;
        font-size:13px;
        &:hover {
          background:$grey;
        }
        @media (max-width:768px) {
          font-size:12px;
        }
      }
      &.travel-idea {
        a {
          border-right:7px solid $green;
        }
        h1 {
          color:$green;
        }
        span.more-info {
          background:$green;
          &:hover {
            background:$grey;
          }
        }
      }
      &.truffle-tour {
        a {
          border-right:7px solid $gold;
        }
        h1 {
          color:$gold;
        }
        span.more-info {
          background:$gold;
          &:hover {
            background:$grey;
          }
        }
      }
    }
  }
}
