ul.box-link-header-home {
  list-style:none;
  position:absolute;
  top:-200px;
  z-index:1000;
  left:50%;
  margin-left:-570px;
  max-width:1100px !important;
  a {
    text-align:center;
    text-decoration:none;
    padding:10px 0 50px;
    display:block;
  }
  p,h1,h2 {
    padding:0 15px;
  }
  h1 {
    @include font(40px,1.05em,$weight:900,$color:$wine);
    margin:20px 0 0;
  }
  h2 {
    @include font(21px,1.1em);
    margin:0;
  }
  li {
    float:left;
    background:white;
    position:relative;
    width:(100%/3);
    box-shadow: 0 0 8px rgba(0,0,0,0.7);
    z-index:1;
    margin-top:50px;
    transition:margin-top 0.15s linear;
    border-bottom:7px $wine solid;
    figure {
      display:block;
      padding:0;
      margin:0;
      border:none;
      height:190px;
      overflow:hidden;
      position:relative;
      transition:height 0.15s linear;
    }
    i {
      position:absolute;
      left:50%;
      bottom:13px;
      font-size:30px;
      margin-left:-15px;
      transition:opacity 0.15s linear;
      color:$wine;
      opacity:1;
    }
    span {
      position:absolute;
      @include font(16px);
      display:block;
      width:120px;
      padding:7px 0;
      left:50%;
      bottom:13px;
      margin-left:-60px;
      text-align:center;
      border-radius:4px;
      transition:opacity 0.15s linear,background 0.15s linear,color 0.15s linear;
      border:1px solid $wine;
      opacity:0;
      &:hover {
        background:$wine;
        color:white;
      }
    }
    &.active {
      margin-top:0;
      z-index:2;
      span {
        opacity:1;
        z-index:5;
      }
      i {
        opacity:0;
        z-index:1;
      }
      figure {
        height:280px;
      }
      &:before,&:after {
        clip-path: polygon(0 0, 100% 0, 100% 50%, 50% 100%, 0 50%);
        content:'';
        bottom:-18px;
        background:white;
        left:0;
        width:100%;
        height:25px;
        position:absolute;
      }
      &:before {
        bottom:-28px;
        background:$wine;
      }
      &.fun:before {
        background:$gold;
      }
      &.sommelier:before {
        background:$green;
      }
    }
    &.fun {
      border-color:$gold;
      span {
        border-color:$gold;
        &:hover {
          background:$gold;
        }
      }
      i {
        color:$gold;
      }
      h1 {
        color: $gold;
      }
    }
    &.sommelier {
      border-color:$green;
      span {
        border-color:$green;
        &:hover {
          background:$green;
        }
      }
      i {
        color:$green;
      }
      h1 {
        color:$green;
      }
    }
  }
  @media (max-width:1200px) {
    position:relative;
    top:auto;
    left:auto;
    margin:0 auto;
    display:block;
    li {
      width:100%;
      margin-top:0;
      margin-bottom:50px;
      figure {
        float:left;
        height:190px;
        width:300px;
        margin:0 20px 0 0;
        img {
          width:100%;
        }
      }
      &.active {
        figure {
          height:190px;
        }
      }
    }
  }
  @media (max-width:768px) {
    li {
      margin-bottom:35px;
      h1 {
        margin-top:5px;
      }
      p, figure {
        display:none;
      }
      span {
        bottom:0;
        padding:5px 0;
        font-size:12px;
      }
      i {
        bottom:0;
      }
    }
    h1 {
      font-size:32px;
    }
    h2 {
      font-size:18px;
    }
  }
}