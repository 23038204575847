footer.footer {
  a {
    color:white;
    transition:opacity 0.15s linear;
    &:hover {
      opacity:0.85;
    }
  }
  .bckg-img {
    width:100%;
    min-height:150px;
    background-size:cover;
    //image-rendering:pixelated;
    background-position:center center;
    background-repeat:no-repeat;
    background-image:url(/static/img/footer.jpg);
    img {
      float:right;
      max-width:470px;
      margin-top:25px;
    }
  }
  .bck-black {
    background:rgba(black,0.8);
    padding:25px 0;
    margin-top:320px;
    ul.social {
      list-style:none;
      padding:0;
      line-height:85px;
      text-align:center;
      li,a {
        vertical-align:middle;
        display:inline-block;
        line-height:85px;
      }
      a {
        color:white;
        text-decoration:none;
        font-size:18px;
        transition:color 0.15s linear;
        &:hover {
          color:$gold;
        }
        i {
          margin:0 15px;
          font-size:50px;
          vertical-align:middle;
          display:inline-block;
        }
      }
      li {
        padding:0 20px;
        box-sizing:border-box;
        border-right:2px solid white;
        &:last-child {
          border-right:none;
        }
      }
      @media (max-width:768px) {
        line-height:45px;
        a {
          span {
            display:none;
          }
          i {
            font-size:35px;
          }
        }
        li,a {
          line-height:35px;
        }
      }
    }
  }
  .bckg-wine {
    background:$wine;
    position:relative;
    ul.contact {
      list-style:none;
      padding:0 15px;
      color:white;
      @media (max-width:1100px) {
        padding-top:80px;
      }
      li {
        width:20%;
        float:left;
        &:last-child {
          text-align:right;
          width:40%;
        }
        &:first-child {
          width:40%;
        }
        &.center-img {
          text-align:center;
          line-height:20px;
        }
        line-height:350px;
        .center-v, img {
          vertical-align:middle;
          line-height:350px;
          display:inline-block;
        }
        img {
          max-width:250px;
          line-height:20px;
          margin-top:20px;
          &.logo {
            margin-top:90px;
          }
        }
        @media (max-width:1100px) {
          line-height:inherit;
          &.center-img {
            position:absolute;
            top:20px;
            left:0;
            width:100%;
            line-height:50px;
            img {
              display:block;
              margin:0 auto 15px;
            }
          }
          &:first-child {
            padding-top:150px;
          }
          &:last-child,&:first-child {
            width:100%;
            text-align:center;
          }
          .center-v {
            line-height:inherit;
          }
        }
      }
      p {
        color:white;
        strong {
          color:white;
          font-weight:900;
        }
      }
    }
  }
  .bckg-wine-dark {
    background:$wine/2;
    padding:15px 0;
    ul {
      list-style:none;
      padding:0 15px;
    }
    li {
      float:right;
      color:white;
      font-weight:200;
      font-size:15px;
      line-height:65px;
      a {
        margin-right:20px;
        span {
          text-decoration:none;
          display:inline-block;
          margin-right:10px;
        }
      }
      vertical-align:middle;
      &:first-child {
        float:left;
      }
      &:last-child {
        i {
          font-size:50px;
          margin-left:15px;
          line-height:65px;
          vertical-align:middle;
        }
      }
      @media (max-width:1100px) {
        width:100%;
        text-align:center;
      }
      @media (max-width:768px) {
        font-size:12px;
        &:last-child {
          i {
            font-size:35px;
            margin-right:2px;
          }
        }
      }
    }
  }
}
.newsleeter {
  padding-top:10px;
  padding-bottom:10px;
  display:flex;
  align-items:center;
  justify-content:center;
  .text {
    padding-right:75px;
    h2,p {
      margin:0;
      color:white;
    }
    a {
      padding-left:5px;
      text-transform:uppercase;
      display:inline-block;
    }
  }
  #mc_embed_signup_scroll {
    display:flex;
    align-items:stretch;
    input {
      padding:15px;
    }
    #mc-embedded-subscribe {
      border:none;
      box-shadow:none;
      background-color:$wine;
      color:white;
      transition:0.15s background-color linear,0.15s color linear;
    &:hover {
      background-color:$grey;
    }
    }
  }
  @media (max-width:768px) {
    flex-direction:column;
    .text {
      width:100%;
      padding-right:0;
      padding-bottom:15px;
      text-align:center;
    }
  }
}