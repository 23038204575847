a.fancybox-prev,a.fancybox-next {
  width:35%;
}
a.fancybox-prev {
  left:-8px;
  cursor:w-resize;
  span {
    cursor:w-resize;
  }
}
a.fancybox-next {
  right:-8px;
  cursor:e-resize;
  span {
    cursor:e-resize;
  }
}
@media (max-width:768px) {
  a.fancybox-prev {
    left:0;
  }
  a.fancybox-next {
    right:0;
  }
  a.fancybox-close {
    top:0;
    right:0;
  }
}
a.fancy {
  img {
    width:100%;
  }
  position:relative;
  overflow:hidden;
  text-decoration:none;
  span {
    transition:width 0.15s linear,height 0.15s linear,top 0.15s linear,left  0.15s linear;
    position:absolute;
    width:0;
    height:0;
    top:50%;
    left:50%;
    background:rgba($wine,0.5);
  }
  &:hover span {
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
  i {
    transition:opacity 0.2s linear,margin 0.2s linear,font-size 0.2s linear;
    position:absolute;
    top:50%;
    left:50%;
    font-size:400px;
    margin:-200px 0 0 -200px;
    color:rgba(white,0.75);
    opacity:0;
  }
  &:hover i {
    font-size:40px;
    margin:-20px 0 0 -20px;
    opacity:1;
  }
  .line-bottom {
    border-bottom:1px solid $grey*3;
  }
  @media (max-width:480px) {
    width:200px;
    display:block;
  }
}

.modal {
  text-align:center;
  p {
    @include font(18px,1.35em,$grey*2.5,400);
  }
  h1 {
    color:$wine;
  }
  img {
    max-width:90%;
  }
  .price {
    @include font(22px,1.35em,$grey,400);
  }
}