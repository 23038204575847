.home-slideshow, .title-page {
  width:100%;
  height:800px;
  position:relative;
  @media (max-width:1200px) {
    height:650px;
  }
  @media (max-width:1000px) {
    height:500px;
  }
  @media (max-width:800px) {
    height:450px;
  }
  .box-slideshow {
    width:100%;
    height:100%;
    background-size:cover;
    //image-rendering:pixelated;
    background-position:center center;
    background-repeat:no-repeat;
  }
  .box-title {
    position:absolute;
    left:0;
    width:100%;
    text-align:center;
    z-index:1000;
    bottom:270px;
    @media (max-width:1200px) {
      bottom:130px;
    }
    h1 {
      @include font(96px,$color:white,$weight:900);
      text-transform:uppercase;
      @media (max-width:600px) {
        font-size:11.5vw;
        line-height:0.95em;
      }
    }
    h2 {
      @include font(48px,$color:white,$weight:900);
      @media (max-width:600px) {
        font-size:8vw;
        line-height:1.85em;
      }
    }
    h1,h2 {
      text-shadow: 0 0 10px rgba(0,0,0,0.7);
      margin:0;
      padding:0;
    }
  }
}
.title-page {
  height:500px;
  @media (max-width:1200px) {
    height:400px;
  }
  @media (max-width:1000px) {
    height:300px;
  }
  @media (max-width:800px) {
    height:250px;
  }
  .box-title {
    bottom:35px;
    h2 {
      line-height:1em;
    }
  }
}