.content-gallery {
  padding-bottom:80px;
}
ul.gallery {
  list-style:none;
  padding:0;
  margin:0;
  li {
    float:left;
    width:$w-100/4;
    a {
      display:block;
      margin:8px;
    }
    @media (max-width:768px) {
      width:$w-100/3;
      a {
        margin:6px;
      }
    }
    @media (max-width:500px) {
      width:$w-100/2;
      a {
        margin:4px;
      }
    }
  }
}