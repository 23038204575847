.customized-tour {
  margin-bottom:85px;
  line-height:180px;
  img {
    display:inline-block;
    width:80px;
    margin-right:100px;
    vertical-align:middle;
  }
  p {
    vertical-align:middle;
    width:550px;
    margin:0;
    display:inline-block;
  }
  a {
    margin-top:60px;
    float:right;
    width:130px;
    line-height:normal;
    position:relative;
    padding:10px 60px 10px 10px;
    color:white;
    background:$wine;
    transition:background 0.15s linear;
    border-radius:3px;
    text-transform:uppercase;
    text-decoration:none;
    font-size:18px;
    margin-left:40px;
    @media (max-width:768px) {
      font-size:14px;
    }
    &:hover {
      background:$grey;
    }
    i {
      position:absolute;
      top:50%;
      right:5px;
      font-size:40px;
      margin-top:-20px;
    }
  }
  @media (max-width:1100px) {
    a {
      float:none;
      display:block;
      margin:15px auto;
      clear:both;
    }
    line-height:normal;
    img {
      float:left;
      width:10%;
      margin:0 5% 15px 0;
    }
    p {
      width:85%;
      float:left;
      margin-bottom:30px;
    }
  }
}
.box-news-home {
  background:$grey*4.5;
  .wrap {
    padding-top:35px;
    padding-bottom:15px;
  }
  .cycle-pager-events {
    position:relative;
    margin:15px 0;
    text-align:center;
    span {
      font-size:60px;
      color:$grey*3;
      cursor:pointer;
      transition:color 0.15s linear;
      margin-right:5px;
      &.cycle-pager-active {
        color:$wine;
      }
      &:hover {
        color:$gold;
      }
    }
  }
  .img  {
    float:left;
    width:40%;
    height:300px;
    background-size:cover;
    image-rendering:pixelated;
    background-position:center center;
    background-repeat:no-repeat;
    background-image:url(/static/img/home/bck-tour-and-more.jpg);
    display:block;
    margin-right:90px;
    @media (max-width:1050px) {
      width:35%;
      height:230px;
    }
    @media (max-width:768px) {
      width:45%;
      height:230px;
    }
    @media (max-width:580px) {
      width:100%;
      height:200px;
      margin:0 0 15px;
    }
  }
  p.title {
    @include  font(30px,1em,$color:$wine,$weight:900);
    margin:0 0 10px;
    text-transform:uppercase;
    @media (max-width:768px) {
      font-size:20px;
    }
  }
  p.date {
    color:$green;
  }
  .feed-icon {
    position:absolute;
    bottom:0;
    right:0;
    padding-top:20px;
    color:$grey;
    i {
      margin-left:12px;
      color:$facebook;
    }
    span {
      font-size:15px;
      @media (max-width:800px) {
        font-size:13px;
      }
    }
    a {
      color:$facebook;
      transition:color 0.15s linear;
      font-weight:600;
      text-decoration:none;
      &:hover {
        color:$grey;
      }
    }
  }
  ul.cycle-slideshow {
    padding:0 0 20px;
    margin:0;
    border-bottom:5px $wine solid;
    overflow:hidden;
    min-height:350px;
    @media (max-width:1050px) {
      min-height:300px;
    }
    @media (max-width:580px) {
      min-height:360px;
    }
    li {
      width:100%;
      float:left;
      position:relative;
      @media (max-width:580px) {
       padding-bottom:25px;
      }
    }
  }
}
ul.events {
  list-style:none;
  padding:0 15px 25px;
  li {
    overflow:hidden;
    position:relative;
    @include font(18px,$color:$grey*2.5);
    border-top:2px dotted $grey*4;
    padding:20px 15px 20px 75px;
    i.icon-calendar {
      color:$wine;
      opacity:0.5;
      position:absolute;
      left:15px;
      top:50%;
      font-size:38px;
      margin-top:-19px;
    }
    @media (max-width:768px) {
      padding-left:15px;
      i.icon-calendar {
        position:relative;
        font-size:16px;
        left:auto;
        top:auto;
        margin-top:0;
        float:left;
        margin-right:15px;
      }
    }
    &:last-child {
      border-bottom:2px dotted $grey*4;
    }
    strong.date {
      display:block;
      padding-bottom:7px;
      color:$wine;
    }
    &:before {
      content:'';
      background:$grey*4.7;
      width:100%;
      height:100%;
      left:-150%;
      top:0;
      display:block;
      position:absolute;
      z-index:-1;
      transition:left 0.2s linear;
    }
    &:hover:before {
      left:0;
    }
  }
}